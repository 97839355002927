<template>
  <div class="mx-auto" style="max-width: 300px;">
    <h2>Perfil</h2>
    <hr/>
    <b-form @submit="update">
        <b-button variant="link" @click="changePassword = !changePassword">
            {{ !changePassword ? 'Cambiar Clave' : 'Actualizar datos' }}</b-button>
        <b-form-group id="input-group-first-name" label="Nombres" label-for="input-first-name" v-if="!changePassword">
            <b-form-input id="input-first-name" v-model="form.first_name" required placeholder="Nombres"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-last-name" label="Apellidos" label-for="input-last-name" v-if="!changePassword">
            <b-form-input id="input-last-name" v-model="form.last_name" required placeholder="Apellidos"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-email" label="Correo electrónico" label-for="input-email" class="my-4" v-if="!changePassword">
            <b-form-input id="input-email" v-model="form.email" type="email" required placeholder="Email"></b-form-input>
        </b-form-group>

      <b-form-group id="input-group-username" label="Usuario" label-for="input-username" v-if="!changePassword">
        <b-form-input id="input-username" v-model="form.username" required placeholder="Nombre de Usuario"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-old-password" label="Clave anterior" label-for="input-old-password" v-if="changePassword">
        <b-form-input id="input-old-password" v-model="form.old_password" type="password" required placeholder="Clave anterior"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-new-password" label="Clave nueva" label-for="input-new-password" v-if="changePassword">
        <b-form-input id="input-new-password" v-model="form.new_password" type="password" required placeholder="Clave nueva"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-repeated-password" label="Repetir Clave" label-for="input-repeated-password"
        description="Las contraseñas deben coincidir." v-if="changePassword">
        <b-form-input id="input-repeated-password" v-model="form.repeated_password" type="password" required
          placeholder="Clave" >
        </b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Actualizar</b-button>
    </b-form>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import axios from '../../utils/api'
import router from '@/router/index'
export default {
    data() {
        return {
            form: {
                username: '',
                email: '',
                first_name: '',
                last_name: '',
                old_password: '',
                new_password: '',
                repeated_password: '',
                user_id: this.$store.state.userData.user_id,
            },
            changePassword: false,
        }
    },
    methods: {
        getProfile() {
          axios.get(`/users/${this.form.user_id}/`)
          .then((response) => {
              this.form.username = response.data.username
              this.form.email = response.data.email
              this.form.first_name = response.data.first_name
              this.form.last_name = response.data.last_name
              this.form.user_id = response.data.id
          })
          .catch((error) => {
              console.log(error)
          })
        },
        update(event) {
          event.preventDefault()
          let form = {}
          if (!this.changePassword) {
            form = {
              username: this.form.username,
              email: this.form.email,
              first_name: this.form.first_name,
              last_name: this.form.last_name,
            }
            axios.patch(`/users/${this.form.user_id}/`, form)
              .then((response) => {
                  console.log(response)
              })
              .catch((error) => {
                  console.log("profile error", error)
              })
          } else {
            form = {
              old_password: this.form.old_password,
              new_password: this.form.new_password,
              repeated_password: this.form.repeated_password,
            }
            axios.put(`/password/`, form)//${this.form.user_id}/
              .then(() => {
                  router.replace('/')
              })
              .catch((error) => {
                  console.log("profile error", error)
              })
          }
          
        },
    },
    created() {
        this.getProfile()
    }
}
</script>
